import {
    BlobWriter,
    BlobReader,
    type ReadableReader,
    Reader,
    ZipWriter, type ZipWriterAddDataOptions,
} from "@zip.js/zip.js";

export class Zip {
    private zip;

    public constructor() {
        this.zip = new ZipWriter(new BlobWriter("application/zip"));
    }

    private create() {

        this.zip = new ZipWriter(new BlobWriter("application/zip"));
    }

    private reset() {
        this.zip = null;
    }

    public async add(filename: string,
                     reader?:
                         | ReadableReader
                         | ReadableStream
                         | Reader<unknown>[]
                         | ReadableReader[]
                         | ReadableStream[],
                     options?: ZipWriterAddDataOptions) {
        await this.zip?.add(filename, reader, options)
    }

    public async close() {
        await this.zip?.close()
    }

    public async addAll(blobs: { filename: string; data: Blob }[]) {
        if (this.zip) {
            await Promise.all(blobs.map((blob) => this.zip.add(blob.filename, new BlobReader(blob.data))));
            const blob=this.zip?.close();
            this.reset();
            return blob;
        }
        return null;
    }
}

function blobsToZip(blob: Blob[]) {

}

function add() {

}
