import type {PdfResponse, PdfResponseGrouped} from "~/types/Pdf";
import {downloadFileFromBlob} from "~/utils/download"
export function downloadPdfFromPdfService(resp: PdfResponseGrouped|Array<PdfResponse>): void {
  if(resp?.groups==undefined){
    if(resp.length<=2) {
        for (let i = 0; i < resp.length; i++) {
            const blob = dataToBlob(resp[i].data, "application/pdf");
            downloadFileFromBlob(blob, resp[i].filename);
        }
    }else{
        const zip=new Zip()
        const blobs=resp.map((item) => {
          const blob = dataToBlob(item.data, "application/pdf");
            return {filename:item.filename,data:blob};
        });
        zip.addAll(blobs).then(function(blob){
            if(blob){
                downloadFileFromBlob(blob,"factures.zip");
            }
          
        }).finally(()=>{
          zip.close();
        });

    }
  }else{
    for (const [key, values] of Object.entries(resp.groups)) {
      const zip=new Zip()
      const blobs=[]
      for(let value of values as Array<PdfResponse>){
        const blob = dataToBlob(value.data, "application/pdf");
        blobs.push({filename:value.filename,data:blob});
       }
       
       zip.addAll(blobs).then(function(blob){
         
        if(blob){
               console.log("downloading zip file");
               downloadFileFromBlob(blob,key+".zip");

           }
          
       }).finally(()=>zip.close());

    }
  }
}
