<script setup lang="ts">
const colorMode = useColorMode()
const  isLightTheme=computed(()=>colorMode.preference === 'light')

defineOptions({
  inheritAttrs: false
});

function toggleTheme(){
   if(colorMode.preference==="light"){
     colorMode.preference="dark"
   }else{
     colorMode.preference="light"
   }
}
</script>

<template>
  <button  @click="toggleTheme" class="border rounded-md border-dark p-2 w-8 h-8  flex" :class="{'bg-surface-700':!isLightTheme,'bg-surface-50':isLightTheme}">
    <i class="pi "   :class="{'border-black/70 pi-sun text-black':isLightTheme,'border-white/70 pi-moon':!isLightTheme}"></i>
  </button>


</template>

<style scoped>
.sunny::before {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  content:url("~/assets/icons/sun.svg");
}
</style>
